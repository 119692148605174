/* eslint-disable no-unused-expressions, no-param-reassign */
import runAfterPageLoad from "~/src/runAfterPageLoad";

runAfterPageLoad(initBlockIframes);

function initBlockIframes() {
  const iframes = document.querySelectorAll("iframe");
  const srcRegex = /^https?:\/\/player\.vimeo\.com\/video\/\d+$/gim;

  iframes.forEach((iframe) => {
    iframe.sandbox.add("allow-scripts");
    srcRegex.test(iframe.src) ? (iframe.src += "?dnt=true") : null;
  });
}
